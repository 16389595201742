import React from 'react';
var Logos = function (_a) {
    var localizationCode = _a.localizationCode;
    var isCZ = localizationCode === 'CZ' || localizationCode === 'SK';
    var links = {
        'NET service solution, s.r.o.': isCZ ? 'https://www.net-service.cz' : 'https://www.net-service.cz/en',
        ISPadmin: isCZ ? 'https://ispadmin.eu' : 'https://ispadmin.eu/en',
        FlowPRO: 'https://www.flowpro.eu',
        VPNpilot: isCZ ? 'https://www.vpnpilot.eu' : 'https://www.vpnpilot.eu/en',
        SolarPilot: 'https://www.solarpilot.eu',
    };
    var svg = {
        'NET service solution, s.r.o.': 'logo_net-service_grey.svg',
        ISPadmin: 'logo_ispadmin_grey.svg',
        FlowPRO: 'logo_flowpro_grey.svg',
        VPNpilot: 'logo_vpnpilot_grey.svg',
        SolarPilot: 'logo_solarpilot_grey.svg',
    };
    var logoNames = ['NET service solution, s.r.o.', 'ISPadmin', 'FlowPRO', 'VPNpilot', 'SolarPilot'];
    return (React.createElement("div", { className: "tw-max-w-4xl tw-m-auto tw-flex tw-justify-around tw-items-center tw-py-3 tw-gap-x-4 tw-gap-y-2 tw-flex-wrap" }, logoNames.map(function (logo) { return (React.createElement("a", { href: links[logo], target: "_blank", key: logo, rel: "noopener noreferrer" },
        React.createElement("img", { className: "tw-max-h-6", src: "/images/logos/" + svg[logo], alt: logo, title: logo }))); })));
};
export default Logos;
